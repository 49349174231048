import Api from './api';

export default {
    CreateTempMember(emailDto)
    {
        return Api.post('TempMemberApi/CreateTempMember', emailDto);
    },   

    ActivateTempMember(activateDto)
    {
        return Api.post('TempMemberApi/ActivateTempMember', activateDto);
    },

    CreateMemberFromTemp(createMemberDto)
    {
        return Api.post('TempMemberApi/CreateMemberFromTemp', createMemberDto);
    },
    
    LogIn(loginDto) {
        return Api.post('TempMemberApi/LogIn', loginDto, { withCredentials:true });
    },
}