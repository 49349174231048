import Api from './api';

export default {
    getDictionary() {
        return Api.get('CmsApi/GetDictionaryItems');
    },

    sendMail(mailDto) {
        return Api.post('CmsApi/SendMail', mailDto);
    },

    sendSupportMail(mailDto) {
      return Api.post('CmsApi/SendSupportMail', mailDto);
  },

    LogIn(loginDto) {
        return Api.post('CmsApi/LogIn', loginDto, { withCredentials:true });
    },
}
