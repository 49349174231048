import { defineStore } from "pinia";
import CmsApi from "../apis/cms";

export const useDictionaryStore = defineStore("dictionary", {
  state: () => ({
    dictionary: [],
  }),

  getters: {
    getDictionary: (state) => {
      return state.dictionary;
    },

    isLoaded(state)
    {
      return state.dictionary = null & state.dictionary.length > 0
    }
  },

  actions: {
    loadDictionary() {
      if(this.dictionary == null || this.dictionary.length === 0)
      {
        CmsApi.getDictionary().then(response => {
          this.dictionary = response.data.result;
        });
      }
    },

    getText(key) {
      let item = this.dictionary.find((item) => item.key === String(key));
      return item != null ? item.value : "";      
    },
  },
});